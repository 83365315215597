/* html & body styles */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%
}

.root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}

/* Hero styles */
.heroImage {
  width: 100%;
  object-fit: contain;
  object-position: center;
  max-height: 70vh;
}

/* Link styles */
a {
  color: #017cb6 !important;
  text-decoration: none !important;
}

footer a {
  color: #004080 !important;
}

a:hover {
  text-decoration: underline !important;
}

/* Logo styles */
.brand-name {
  font-weight: 700;
  padding: 0 15px;
  border-radius: 45%;
  color: #8c4234 !important;
  background-color: #fee1b9;
  border: 3px solid #8c4234;
}

.brand-name:hover {
  text-decoration: none !important;
}

/* Card styles */
.cardContainer {
  margin: auto !important;
  width: 50rem !important;
}

.cardTitle {
  text-align: center !important;
  color: #8c4234 !important;
}